import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export const getInspectionGroupValidators = (availablePieces: number) => {
  return {
    article: [{ value: '', disabled: availablePieces === 0 }, [Validators.required, articleValidator]],
    quantity: [
      { value: null, disabled: availablePieces === 0 },
      [Validators.required, Validators.min(1), (control: AbstractControl) => Validators.max(availablePieces)(control)],
    ],
  };
};

const articleValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
  return typeof control.value === 'string' ? { invalidArticle: true } : null;
};

export function articleQuantityValidator(stockQuantity?: boolean): ValidatorFn {
  return (): { [key: string]: boolean } | null => {
    return stockQuantity ? { invalidArticleQuantity: true } : null;
  };
}

export function articleValidatorFn(): ValidatorFn {
  return articleValidator;
}
