import { Component, Input, OnInit } from '@angular/core';
import { InspectionItemsGalleryDto } from 'src/app/models/inspection-result';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-inspection-items-group-gallery',
  templateUrl: './inspection-items-group-gallery.component.html'
})
export class InspectionItemsGroupGalleryComponent implements OnInit {
  @Input() inspectionId!: string;
  isLoading = false;
  inspectionItemsGallery: InspectionItemsGalleryDto [] = [];

  constructor(private galleryService: GalleryService) {}

  ngOnInit(): void {
    this.getGallery();
  }

  getGallery() {
    this.isLoading = true;
    this.galleryService.getInspectionGalleryGroupedByItem(this.inspectionId).subscribe({
      next: (data) => {
        this.inspectionItemsGallery = data;
        this.isLoading = false;
      },
      error: () => (this.isLoading = false),
    });
  }
}
