import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InspectionItem } from '../models/inspection-item';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  constructor(private http: HttpClient) {}

  getItems(inspectionId: string, searchText: string, currentPage?: number) {
    return this.http.get<{ items: InspectionItem[]; totalNumberOfItems: number }>(
      `${
        environment.apiBaseUrl
      }/stock-items/search/${inspectionId}?SearchText=${searchText}&CurrentPage=${currentPage}&PageSize=10&Culture=${localStorage.getItem(
        'i18nextLng'
      )}`
    );
  }
}
