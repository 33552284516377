import jsPDF, { ImageProperties } from "jspdf";
import { InspectionItemFailureReasons, InspectionItemType, InspectionResultGallery } from "../models/inspection-result";
import { TranslationService } from "./translation.service";
import { ExportGalleryOverviewDto } from "../models/export-pdf.model";
import { ExportPdfBaseService } from "./export-pdf-base.service";
import i18next from "i18next";

export class ExportGalleryOverviewService extends ExportPdfBaseService {
  private readonly failureReasonImageIncrement = 60;
  private readonly numberToDivideDimensions = 2;

  private availableSpaceY: number;
  private inspectionItemGroupFontSize: number;
  private failureReasonFontSize: number;

  constructor(
    protected override translationService: TranslationService,
    private exportGalleryOverview: ExportGalleryOverviewDto
  ) {
    super(exportGalleryOverview.inspectionResultDetails, translationService);

    this.jsPdf = new jsPDF({
      unit: "px",
      orientation: "portrait",
      format: "A4",
    });

    this.availableSpaceY = this.jsPdf.getCurrentPageInfo().pageContext.mediaBox.topRightY;
    this.inspectionItemGroupFontSize = 16;
    this.failureReasonFontSize = 12;
  }

  downloadPdf(): void {
    this.addInspectionResultTitle();
    this.addInspectionDetails();

    this.exportGalleryOverview.failureReasonsList.forEach((resultGallery) => {
      this.adjustPositionsForGroupHeader(resultGallery);
      this.addGroupName(resultGallery);

      resultGallery.inspectionItems.forEach((item) => {
        this.addItemName(item);

        item.inspectionItemFailureReasons.forEach((reason) => {
          const image = this.getFailureReasonImage(item, reason);

          if (image) {
            const imageProperties = this.jsPdf.getImageProperties(image);

            this.adjustPositionsForImage(imageProperties);
            this.addFailureReasonImage(reason.failureReasonId, image, imageProperties);
          }
        });
      });
    });

    this.savePdf();
  }

  private savePdf(): void {
    const branchId = this.branchName.substring(0, this.branchName.indexOf(" "));
    const fileName = `YQC-Result_${branchId}_Gallery_${this.inspectionDate}_${this.auditorName}_${i18next.language}.pdf`;

    this.jsPdf.save(fileName);
  }

  private getFailureReasonImage(item?: InspectionItemType, reason?: InspectionItemFailureReasons): HTMLImageElement {
    const imageId = `image-${item?.articleIdentifier}-${reason?.failureReasonId}`;
    const image = document.getElementById(imageId) as HTMLImageElement;
    return image;
  }

  private addItemName(item: InspectionItemType): void {
    this.jsPdf.setFontSize(this.inspectionItemGroupFontSize);

    const itemName = `${item.articleIdentifier} - ${item.name}`;

    this.jsPdf.text(itemName, this.currentPositionX, this.currentPositionY);
    this.currentPositionY += this.currentIncrement;
  }

  private addGroupName(resultGallery: InspectionResultGallery): void {
    this.jsPdf.setFontSize(this.inspectionItemGroupFontSize);

    const groupName = this.translationService.translate(
      `ConfigurationList.InspectionGroup.${resultGallery.inspectionGroupIdentifier}`
    );

    this.jsPdf.text(groupName, this.currentPositionX, this.currentPositionY);
    this.currentPositionY += this.currentIncrement;
  }

  private adjustPositionsForGroupHeader(resultGallery: InspectionResultGallery): void {
    const firstInspectionItem = resultGallery.inspectionItems[0];
    const firstInspectionItemFailureReason = firstInspectionItem?.inspectionItemFailureReasons[0];

    const image = this.getFailureReasonImage(firstInspectionItem, firstInspectionItemFailureReason);
    if (image) {
      const imageProperties = this.jsPdf.getImageProperties(image);
      const necessarySpaceForFailureReasonImage =
        imageProperties.height / this.numberToDivideDimensions +
        this.inspectionItemGroupFontSize +
        this.inspectionItemGroupFontSize +
        this.failureReasonFontSize +
        this.failureReasonImageIncrement;
      const hasSpaceAvailable = this.availableSpaceY - this.currentPositionY > necessarySpaceForFailureReasonImage;

      if (!hasSpaceAvailable) {
        this.adjustCurrentCoordinates();
      }
    }
  }

  private adjustCurrentCoordinates(): void {
    this.jsPdf.addPage();
    this.currentPositionY = 20;
    this.currentIncrement = 20;
    this.availableSpaceY = this.jsPdf.getCurrentPageInfo().pageContext.mediaBox.topRightY;
  }

  private adjustPositionsForImage(imageProperties: ImageProperties): void {
    this.availableSpaceY = this.availableSpaceY - this.currentPositionY;
    const hasSpaceAvailable =
      this.availableSpaceY > imageProperties.height / this.numberToDivideDimensions + this.currentIncrement;

    if (!hasSpaceAvailable) {
      this.adjustCurrentCoordinates();
    }
  }

  private addFailureReasonImage(
    failureReasonId: number,
    image: HTMLImageElement,
    imageProperties: ImageProperties
  ): void {
    const reasonName = this.translationService.translate(`InspectionResult.ItemFailureReasons.List.${failureReasonId}`);

    this.jsPdf.setFontSize(this.failureReasonFontSize);
    this.jsPdf.text(reasonName, this.currentPositionX, this.currentPositionY);
    this.currentPositionY += this.currentIncrement;

    this.jsPdf.addImage(
      image,
      this.currentPositionX,
      this.currentPositionY,
      imageProperties.width / this.numberToDivideDimensions,
      imageProperties.height / this.numberToDivideDimensions
    );
    this.currentPositionY += this.currentIncrement + imageProperties.height / this.numberToDivideDimensions;
  }
}
