import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map, Observable, startWith } from "rxjs";
import { UserModel } from "src/app/models/user.model";
import { DashboardPage } from "src/app/pages/dashboard/dashboard.page";
import { UserService } from "src/app/services/user.service";
import { InspectionService } from "src/app/services/inspections.service";
import { AuthService } from "src/app/services/auth.service";
import { InspectionTypeEnum } from "src/app/enums/inspection-type.enum";
import { getInspectionValidators } from "./create-test-inspection.validators";
import { Router } from "@angular/router";
import { ToastService } from "src/app/services/toast.service";
import { NewInspectionModel } from "src/app/models/inspections.model";

@Component({
  selector: "app-create-test-inspection",
  templateUrl: "./create-test-inspection.component.html",
  styleUrls: ["./create-test-inspection.component.scss"],
})
export class CreateTestInspectionComponent implements OnInit {
  createTestForm: FormGroup = new FormGroup({});
  users: UserModel[] = [];
  filteredUsers?: Observable<UserModel[] | undefined>;
  isLoading = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private inspectionsService: InspectionService,
    private toastService: ToastService,
    private fb: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<DashboardPage>,
    @Inject(MAT_DIALOG_DATA) public data: DashboardPage
  ) {}

  ngOnInit(): void {
    this.createTestForm = this.fb.group(getInspectionValidators(this.authService.getCurrentUser()));
    this.getUsers();
  }

  inspectionTypes = Object.keys(InspectionTypeEnum).filter((item) => {
    return !isNaN(Number(item));
  });

  displayFn(user: UserModel): string {
    return user && user.name ? user.name : "";
  }

  onClose(): void {
    this.dialogRef.close();
  }

  saveDetails() {
    if (this.createTestForm.valid) {
      this.isLoading = true;
      const formData: NewInspectionModel = {
        ...this.createTestForm.value,
        inspectionTypeId: +this.createTestForm.get("inspectionTypeId")?.value,
        numberOfResults: +this.createTestForm.get("numberOfResults")?.value,
        auditor: this.getAuditor(),
        branchId: this.data.branchId,
      };

      this.inspectionsService.saveInspection(formData).subscribe({
        next: (value) => {
          const inspectionId = JSON.parse(value);
          this.isLoading = false;
          this.router.navigateByUrl(`branch/${this.data.branchId}/test-sheet/${inspectionId}`);
          this.dialogRef.close();
        },
        error: () => {
          this.isLoading = false;
          this.toastService.openSnackBar("General.UnknownError");
        },
      });
    }
  }

  private onAuditorChanged() {
    this.filteredUsers = this.createTestForm.controls["auditor"].valueChanges.pipe(
      startWith(""),
      map((value: string | UserModel) => this.filterUsers(value))
    );
  }

  private getUsers() {
    this.userService.getUsers().subscribe((data) => {
      this.users = data;
    });

    this.onAuditorChanged();
  }

  private filterUsers(value: string | UserModel): UserModel[] | undefined {
    const filterValue = typeof value === "string" ? value.toLowerCase() : value.name.toLowerCase();

    return this.users?.filter((option) => option.name?.toLowerCase().includes(filterValue));
  }

  private getAuditor = () => {
    const auditorValue: string | UserModel = this.createTestForm.get("auditor")?.value;
    return typeof auditorValue === "string"
      ? { email: auditorValue }
      : this.users.find((user) => user.email === auditorValue.email);
  };
}
