import { Component } from "@angular/core";

@Component({
  templateUrl: "./select-branch.page.html",
  styleUrls: ["./select-branch.page.scss"],
})
export class SelectBranchPage {
  hasAccess!: boolean;
  constructor() {}

  async ngOnInit(): Promise<void> {
    this.hasAccess = !!sessionStorage.getItem("user_roles");
  }
}
