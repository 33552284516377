<div class="page-container" #parentDiv>
  <div class="page-title">
    <div class="flex justify-between" [ngClass]="{ 'mr-4': parentDiv.offsetWidth !== parentDiv.clientWidth }">
      {{ 'Dashboard.Name' | i18next }}
      <button mat-raised-button color="accent" *ngIf="isAuditor" (click)="openDialog()">
        {{ 'Dashboard.NewTest' | i18next }}
      </button>
    </div>
  </div>
  <section class="dashboard-body">
    <loading *ngIf="isLoading"></loading>
    <section class="dashboard-list" *ngFor="let inspection of inspections">
      <div class="cell bold first">{{inspection.auditor.name || inspection.auditor.email}}</div>
      <div class="cell bold">{{getDateFormat(inspection.dateOfInspection)}}</div>
      <div class="cell">
        <b> {{ 'Dashboard.Inspection.Progress' | i18next }}:</b> {{inspection.progressPercentage}}%
      </div>
      <div class="cell">
        <b> {{ 'Dashboard.Inspection.Status' | i18next }}: </b>{{ 'Dashboard.Inspection.Statuses.' +
        inspection.inspectionStatusId.toString()| i18next }}
      </div>
      <div class="cell bold">
        {{ 'Dashboard.Inspection.Types.' + inspection.inspectionTypeId .toString()| i18next }}
      </div>
      <div class="cell"><b> {{ 'Dashboard.Inspection.Kpi' | i18next }}:</b> {{inspection.kpiPercentage}}%</div>
      <div class="cell last text-right">
        <button
          *ngIf="inspection.isSetupFinished"
          mat-raised-button
          color="primary"
          class="width-100"
          (click)="goTo('inspection-result', inspection.inspectionId)"
        >
          {{ 'Dashboard.View' | i18next }}
        </button>
        <button
          *ngIf="!inspection.isSetupFinished"
          mat-raised-button
          color="primary"
          class="width-100"
          (click)="goTo('test-sheet', inspection.inspectionId)"
        >
          {{ 'Dashboard.Edit' | i18next }}
        </button>
      </div>
      <div class="cell flex justify-center">
        <mat-icon
          *ngIf="inspection.canDelete"
          class="color-primary-blue cursor-pointer"
          (click)="deleteInspection(inspection.inspectionId, getDateFormat(inspection.dateOfInspection))"
          >delete_outline
        </mat-icon>
      </div>
    </section>
  </section>
  <section *ngIf="inspections.length < 1 && !isLoading" class="empty-state">
    <img src="../../../assets/icons/no-data.svg" alt="no-data" class="mb-2" />
    <span class="description">
      {{ 'Dashboard.DefaultEmptyMessage' | i18next }}
      <span *ngIf="isAuditor"
        >{{ 'Dashboard.AuditorOnlyEmptyMessage' | i18next}}
        <a (click)="openDialog()">{{ 'General.Here' | i18next }}</a>.</span
      >
    </span>
  </section>
</div>
