import { AbstractControl, Validators } from '@angular/forms';
import { UserModel } from 'src/app/models/user.model';

const emailPattern = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9_.+-]{2,3}$/gm);

export const getInspectionValidators = (userModel: UserModel) => {
  return {
    numberOfResults: [null, [Validators.required]],
    inspectionTypeId: [null, [Validators.required]],
    dateOfInspection: [new Date(), [Validators.required]],
    auditor: [userModel, [Validators.required, auditorValidator]],
    comment: [null],
  };
};

const auditorValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
  return typeof control.value === 'string'
    ? emailPattern.test(control.value)
      ? null
      : { invalidAuditor: true }
    : null;
};
