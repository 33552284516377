import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/pages/base-page';
import { TestDetailsPdfPreviewComponent } from 'src/app/components/test-details-pdf-preview/test-details-pdf-preview.component';
import { dateFormatOptions } from 'src/app/constants/date-format-options';
import { InspectionResultModel, InspectionResultDetailsDto } from 'src/app/models/inspection-result';
import { EventBusService } from 'src/app/services/event-bus.service';
import { InspectionService } from 'src/app/services/inspections.service';
import { ToastService } from 'src/app/services/toast.service';
import i18next from 'i18next';

@Component({
  templateUrl: './inspection-result.page.html',
  styleUrls: ['./inspection-result.page.scss'],
})
export class InspectionResultPage extends BasePage implements OnInit {
  inspectionResult!: InspectionResultModel;
  isLoading = false;
  inspectionResultDetails!: InspectionResultDetailsDto;

  constructor(
    private inspectionsService: InspectionService,
    private toastService: ToastService,
    public dialog: MatDialog,
    protected override route: ActivatedRoute,
    protected override eventBusService: EventBusService,) {
    super(route, eventBusService);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.emitBranchId(params);
      this.getInspectionResult(params['id']);
    });   
  }

  getDateFormat(date: string) {
    return new Date(date).toLocaleDateString(i18next.language, dateFormatOptions);
  }

  previewPdf(){
    this.dialog.open(TestDetailsPdfPreviewComponent, {
      data: {
        inspectionResultDetails: this.inspectionResultDetails,
        inspectionResult: this.inspectionResult
      },
    });
  }

  private getInspectionResult(inspectionId: string) {
    this.isLoading = true;
    this.inspectionsService.getResults(inspectionId).subscribe({
      next: (data) => {
        this.inspectionResult = data;
        this.inspectionResultDetails = {
            auditorName: this.inspectionResult.header.auditorName,
            branchName: this.inspectionResult.header.branchName,
            dateOfInspection: this.getDateFormat(this.inspectionResult.header.dateOfInspection),
            inspectionId: this.inspectionResult.inspectionId,
            targetNumberOfItems: this.inspectionResult.header.targetNumberOfItems,
            completedByName: this.inspectionResult.header.completedByName
        }
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.openSnackBar('General.UnknownError');
      },
    });
  }
}
