<span>
  <mat-slider class="image-slider" [max]="max" [min]="min" [step]="step">
    <input matSliderThumb [(ngModel)]="value" #slider />
  </mat-slider>

  <img id="image-{{inspectionItemImageDetails.articleIdentifier}}-{{inspectionItemImageDetails.failureReasonId}}"
    [src]="getRightImage(inspectionItemImageDetails.inspectionItemFailureReasonId)"
    class="mr-6 image-container"
    (click)="
      this.openImageModal(
        inspectionItemImageDetails.inspectionItemName,
        inspectionItemImageDetails.failureReasonId,
        inspectionItemImageDetails.inspectionItemFailureReasonId
      )
  "/>
</span>
