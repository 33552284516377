import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BasePagedResult } from "../models/base-paged-result.model";
import { InspectionDetails } from "../models/inspection-details";
import { InspectionResultModel } from "../models/inspection-result";
import { InspectionModel, NewInspectionModel } from "../models/inspections.model";

@Injectable({
  providedIn: "root",
})
export class InspectionService {
  constructor(private http: HttpClient) {}
  getInspections(branchId: string): Observable<BasePagedResult<InspectionModel>> {
    return this.http.get<BasePagedResult<InspectionModel>>(
      `${environment.apiBaseUrl}/inspections/?branchId=${branchId}`
    );
  }

  getInspection(inspectionId: string): Observable<InspectionDetails> {
    return this.http.get<InspectionDetails>(
      `${environment.apiBaseUrl}/inspections/${inspectionId}/?Culture=${localStorage.getItem("i18nextLng")}`
    );
  }

  getResults(inspectionId: string): Observable<InspectionResultModel> {
    return this.http.get<InspectionResultModel>(
      `${environment.apiBaseUrl}/inspections/${inspectionId}/result/?Culture=${localStorage.getItem("i18nextLng")}`
    );
  }

  saveInspection(formData: NewInspectionModel): Observable<string> {
    return this.http.post(`${environment.apiBaseUrl}/inspections`, formData, { responseType: "text" });
  }

  resetConfiguration(inspectionId?: string) {
    return this.http.put<boolean>(`${environment.apiBaseUrl}/inspections/${inspectionId}/reset`, null);
  }

  finishSetup(inspectionId?: string) {
    return this.http.put<boolean>(`${environment.apiBaseUrl}/inspections/${inspectionId}/finish-setup`, null);
  }

  exportInspection(inspectionId: string) {
    return this.http.get<Blob>(
      `${environment.apiBaseUrl}/inspections/${inspectionId}/export/?Culture=${localStorage.getItem("i18nextLng")}`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  deleteInspection(inspectionId: string) {
    return this.http.delete(`${environment.apiBaseUrl}/inspections/${inspectionId}`);
  }
}
