import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GalleryService } from "src/app/services/gallery.service";
import { ImageModalComponent } from "../image-modal/image-modal.component";
import { InspectionItemImageDetailsDto } from "src/app/models/inspection-result";

@Component({
  selector: "app-image-slider",
  templateUrl: "./image-slider.component.html",
  styleUrls: ["./image-slider.component.scss"],
})
export class ImageSliderComponent {
  @Input() inspectionItemImageDetails!: InspectionItemImageDetailsDto;

  max = 3;
  min = 1;
  step = 1;
  value = 1;

  constructor(public dialog: MatDialog, private galleryService: GalleryService) {}

  openImageModal(itemName: string, failureReasonId: number, inspectionItemFailureReasonId: string) {
    const imageData = this.galleryService.getImage(inspectionItemFailureReasonId);
    imageData &&
      this.dialog.open(ImageModalComponent, {
        data: {
          itemName: itemName,
          failureReasonId: failureReasonId,
          imageData: imageData,
        },
      });
  }

  getRightImage(inspectionItemFailureReasonId: string){
    switch(this.value)
    {
      case 1: return this.galleryService.getResizedImage(inspectionItemFailureReasonId, 300);
      case 2: return this.galleryService.getResizedImage(inspectionItemFailureReasonId, 400);
      case 3: return this.galleryService.getResizedImage(inspectionItemFailureReasonId, 500);
      default: return '';
    }
  }
}
