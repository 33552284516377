<header class="header">
  <section class="select-branch-section">
    <a (click)="navigateToBranches()" class="flex justify-center items-center">
      <img src="./../../../assets/icons/header-logo.svg" class="mr-6" />
    </a>
    <div *ngIf="isVisible" class="select-branch-wrapper">
      <app-header-branch-select [branchId]="branchId"></app-header-branch-select>
      <button class="dashboard-button" mat-flat-button color="white" (click)="navigateToDashboard()">
        {{ 'Dashboard.Name' | i18next }}
      </button>
    </div>
  </section>
  <section class="user-and-language">
    <button class="user-name p-2" mat-button [matMenuTriggerFor]="menu">
      <img class="mb-1 mr-1 user-logo" src="./../../../assets/icons/user-logo.svg" width="30" /> {{ username }}
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item color="primary" (click)="logout()">{{ 'Dashboard.Logout' | i18next }}</button>
    </mat-menu>
    <app-language-button></app-language-button>
  </section>
</header>
