import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${environment.apiBaseUrl}/users`);
  }

  saveUser(user: UserModel): Observable<number> {
    return this.http.post<number>(`${environment.apiBaseUrl}/users`, user);
  }

  getRoles(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiBaseUrl}/users/roles`);
  }
}
