import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { DashboardPage } from "./pages/dashboard/dashboard.page";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { AuthModule } from "./modules/auth/auth.module";
import { JwtInterceptor } from "./modules/auth/jwt.interceptor";
import { I18N_PROVIDERS } from "./i18n";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectBranchPage } from "./pages/select-branch/select-branch.page";
import { AppComponent } from "./app.component";
import { MaterialUiModule } from "./modules/material-ui/material-ui.module";
import { I18NextModule } from "angular-i18next";
import { LanguageButtonComponent } from "./components/language-button/language-button.component";
import { CreateTestInspectionComponent } from "./components/create-test-inspection/create-test-inspection.component";
import { ConfigurationListPage } from "./pages/configuration-list/configuration-list.page";
import { TestSheetPage } from "./pages/test-sheet/test-sheet.page";
import { SelectBranchComponent } from "./components/select-branch/homepage-select-branch/select-branch.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { InspectionGroupComponent } from "./components/inspection-group/inspection-group.component";
import { InfoBoxComponent } from "./components/info-box/info-box.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { ExportedInspectionPage } from "./pages/exported-inspection/exported-inspection.page";
import { InspectionResultPage } from "./pages/inspection-result/inspection-result.page";
import { ErrorSortTableComponent } from "./components/error-sort-table/error-sort-table.component";
import { CommentsTableComponent } from "./components/comments-table/comments-table.component";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MyCustomPaginatorIntl } from "./services/paginator-intl.service";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { TestDetailsComponent } from "./components/test-details/test-details.component";
import { GalleryOverviewComponent } from "./components/gallery-overview/gallery-overview.component";
import { MatListModule } from "@angular/material/list";
import { ImageModalComponent } from "./components/image-modal/image-modal.component";
import { MatSliderModule } from "@angular/material/slider";
import { ImageSliderComponent } from "./components/image-slider/image-slider.component";
import { MatInputModule } from "@angular/material/input";
import { TestDetailsPdfPreviewComponent } from "./components/test-details-pdf-preview/test-details-pdf-preview.component";
import { InspectionGroupsGroupGalleryComponent } from "./components/inspection-groups-group-gallery/inspection-groups-group-gallery.component";
import { FailureReasonsGroupGalleryComponent } from "./components/failure-reasons-group-gallery/failure-reasons-group-gallery.component";
import { InspectionItemsGroupGalleryComponent } from "./components/inspection-items-group-gallery/inspection-items-group-gallery.component";
import { LoginCallbackPage } from "./pages/login-callback/login-callback.page";
import { HeaderSelectBranchComponent } from "./components/select-branch/header-select-branch/header-select-branch.component";
import { DokaCommonModule } from "@doka/common";
import { IonicModule } from "@ionic/angular";

@NgModule({
  declarations: [
    DashboardPage,
    FooterComponent,
    HeaderComponent,
    SelectBranchPage,
    AppComponent,
    LanguageButtonComponent,
    CreateTestInspectionComponent,
    ConfigurationListPage,
    TestSheetPage,
    SelectBranchComponent,
    LoadingComponent,
    InspectionGroupComponent,
    InfoBoxComponent,
    ConfirmationDialogComponent,
    ExportedInspectionPage,
    InspectionResultPage,
    ErrorSortTableComponent,
    CommentsTableComponent,
    TestDetailsComponent,
    GalleryOverviewComponent,
    ImageModalComponent,
    ImageSliderComponent,
    TestDetailsPdfPreviewComponent,
    InspectionGroupsGroupGalleryComponent,
    FailureReasonsGroupGalleryComponent,
    InspectionItemsGroupGalleryComponent,
    LoginCallbackPage,
    HeaderSelectBranchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialUiModule,
    I18NextModule.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatListModule,
    MatSliderModule,
    MatInputModule,
    DokaCommonModule,
    IonicModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    I18N_PROVIDERS,
    { provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
