import { Component, Input, OnInit } from '@angular/core';
import { InspectionResultFailures } from 'src/app/models/inspection-result';

@Component({
  selector: 'error-sort-table',
  templateUrl: './error-sort-table.component.html',
  styleUrls: ['./error-sort-table.component.scss'],
})
export class ErrorSortTableComponent implements OnInit {
  @Input() areSortedByArticle = false;
  @Input() groupErrors!: InspectionResultFailures;
  constructor() {}

  ngOnInit(): void {
  }
}
