import { Directive, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Router } from "@angular/router";
import { Observable, Subscription, map, startWith } from "rxjs";
import { Branch } from "src/app/models/branch.model";
import { EventBusActions } from "src/app/models/event-bus-actions";
import { BranchesService } from "src/app/services/branches.service";
import { EventBusService } from "src/app/services/event-bus.service";

@Directive()
export class SelectBranchComponentBase implements OnInit {
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;

  isLoading = false;
  selectedBranch?: Branch;
  branchId!: string;
  branches: Branch[] = [];
  filteredBranches!: Observable<Branch[] | undefined> | undefined;
  selectFormControl = new FormControl(this.selectedBranch);
  eventBusSubscription: Subscription = new Subscription();

  constructor(
    protected branchesService: BranchesService, 
    protected router: Router, 
    protected eventBusService: EventBusService) {}

  ngOnInit(): void {
      this.eventBusSubscription.add(
      this.eventBusService.on(EventBusActions.branchIdReceived, (branchId: string) => {
        this.branchId = branchId;
        this.setCurrentBranch();
      })
    );  

    const hasAccess = !!sessionStorage.getItem("user_roles");
    
    if(hasAccess){
      this.getBranches();
    }
  }

  ngAfterViewInit(): void {
    this.trigger.autocomplete.closed.subscribe(() => {
      const selectedBranch =
        !!this.selectFormControl.value && typeof this.selectFormControl.value === "string"
          ? null
          : this.selectFormControl.value;
          this.setSelectedBranch(selectedBranch);
    });
  }

  displayFn(branch: Branch): string {
    return branch && branch.name ? branch.name : "";
  }

  allowSelection(option: string): { [className: string]: boolean } {
    return {
      "no-data": option === "",
    };
  }

  getBranches() {
    this.isLoading = true;
    this.branchesService.getBranches().subscribe({
      next: (data) => {
        this.branches = data;
        if (this.branchId) {
          this.setCurrentBranch();
        }
        this.filteredBranches = this.selectFormControl.valueChanges.pipe(
          startWith(""),
          map((value) => this.filterBranches(value || ""))
        );
        this.isLoading = false;
      },
      error: () => (this.isLoading = false),
    });
  }

  setCurrentBranch() {
    this.selectedBranch = this.branches.find((x) => x.id == this.branchId);
    this.selectFormControl.setValue(this.selectedBranch);
  }

  setSelectedBranch(branch?: Branch | null) {
    if (branch?.id !== this.selectedBranch?.id) {
      this.selectedBranch = branch ? branch : this.selectedBranch;
      this.selectFormControl.setValue(this.selectedBranch);
    }
  }

  private filterBranches(value: string | Branch): Branch[] | undefined {
    const filterValue = typeof value === "string" ? value.toLowerCase() : "";

    const filteredOptions = this.branches?.filter((option) => option.name.toLowerCase().includes(filterValue));
    return filteredOptions.length > 0 ? filteredOptions : [{ name: "", id: "" }];
  }
}