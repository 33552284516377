<div class="page-container">
  <div class="page-title">
    <div class="flex justify-between mr-4">{{ 'ConfigurationList.Title' | i18next }}</div>
  </div>
  <div class="configuration-list-body">
    <loading *ngIf="isLoading"></loading>
    <div class="configuration-list mb-8">
      <p></p>
      <p>{{'ConfigurationList.MainGroupName' | i18next}}</p>
      <p>{{'ConfigurationList.TotalItems' | i18next}}</p>
      <p>{{'ConfigurationList.TotalPieces' | i18next}}</p>
      <p>{{'ConfigurationList.MaxPieces' | i18next}}</p>
      <p>{{'ConfigurationList.ShareResult' | i18next}}</p>
    </div>
    <div *ngFor="let configuration of configurationList; let index = index" class="configuration-list">
      <p>{{index + 1}}</p>
      <p>{{ 'ConfigurationList.InspectionGroup.' + configuration.inspectionGroupIdentifier.toString() | i18next }}</p>
      <mat-form-field class="mr-2" appearance="fill">
        <mat-label>{{ 'General.Number' | i18next }}</mat-label>
        <input matInput value="{{configuration.totalItemsToTest}}" readonly />
      </mat-form-field>
      <mat-form-field class="mr-2" appearance="fill">
        <mat-label>{{ 'General.Number' | i18next }}</mat-label>
        <input matInput value="{{configuration.totalPiecesToTest}}" readonly />
      </mat-form-field>
      <mat-form-field class="mr-2" appearance="fill">
        <mat-label>{{ 'General.Number' | i18next }}</mat-label>
        <input matInput value="{{configuration.maxPiecesToTestPerItem}}" readonly />
      </mat-form-field>
      <p>{{configuration.percentage}} %</p>
    </div>
    <div class="configuration-list footer">
      <p></p>
      <span>{{ 'General.Summe' | i18next }}</span>
      <span> {{totalItems}}</span>
      <span> {{totalPieces}}</span>
    </div>
  </div>
</div>
