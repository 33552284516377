<div class="sort-card">
  <div *ngFor="let group of groupErrors.groups">
    <div class="flex justify-between main-section">
      <div>
        {{
          areSortedByArticle
            ? group.details.text
            : ("InspectionResult.ItemFailureReasons.List." + group.details.failureReasonId | i18next)
        }}
      </div>
      <div class="quantity-box">{{ group.details.value }}</div>
    </div>
    <div *ngFor="let item of group.items" class="flex justify-between secundary-section">
      <div>
        {{
          areSortedByArticle
            ? ("InspectionResult.ItemFailureReasons.List." + item.failureReasonId | i18next)
            : item.text
        }}
      </div>
      <div class="quantity-box">{{ item.value }}</div>
    </div>
  </div>
  <div class="flex justify-between total-section">
    <div>{{ "InspectionResult.TotalDefects" | i18next }}</div>
    <div class="quantity-box">{{ groupErrors.total }}</div>
  </div>
</div>
