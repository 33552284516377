<div>
  <div class="label mb-5">{{ "Branch.Select" | i18next }}</div>
  <loading *ngIf="isLoading"></loading>
  <form class="flex" (ngSubmit)="saveSelectedBranch()">
    <mat-form-field class="branch-select mr-6" appearance="fill">
      <mat-label>{{ "Branch.Select" | i18next }}</mat-label>
      <input
        type="text"
        matInput
        [formControl]="selectFormControl"
        [matAutocomplete]="auto"
        placeholder="{{ 'Branch.Select' | i18next }}"
        class="selected-branch-input"
      />
      <mat-autocomplete [panelWidth]="'auto'" class="select-branch-autocomplete" #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option
          *ngFor="let branch of filteredBranches | async"
          [value]="branch"
          [ngClass]="allowSelection(branch.name)"
        >
          {{ branch.name || "Branch.NoMatch" | i18next }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="selectFormControl.hasError('required')">
        {{ "Branch.Select" | i18next }} {{ "General.InputRequired" | i18next }}
      </mat-error>
      <mat-error *ngIf="!selectFormControl.hasError('required') && selectFormControl.errors?.['invalidBranch']">
        {{ "Branch.InvalidBranch" | i18next }}
      </mat-error>
    </mat-form-field>
    <button mat-raised-button color="accent" type="submit" class="submit-branch">
      {{ "Branch.Continue" | i18next }}
    </button>
  </form>
</div>
