<main class="test-details-pdf-preview-modal test-details-font">
    <div class="inspection-result-title flex justify-between mr-4">{{ 'InspectionResult.Title' | i18next }}</div>
    <div class="header">
      <span class="mr-1">{{ 'General.Date' | i18next }}:</span>
      <span class="mr-4">{{getDateFormat(inspectionResult.header.dateOfInspection)}}</span>
      <span class="mr-1">{{ 'InspectionResult.Auditor' | i18next }}:</span>
      <span class="mr-4">{{inspectionResult.header.auditorName}}</span>
      <div *ngIf="inspectionResult.header.completedByName">
        <span class="mr-1">{{ 'InspectionResult.CompletedBy' | i18next }}:</span>
        <span class="mr-4">{{inspectionResult.header.completedByName}}</span>
      </div>
      <span class="mr-1">{{ 'InspectionResult.TargetValue' | i18next }}:</span>
      <span>{{inspectionResult.header.targetNumberOfItems}}</span>
    </div>
  <loading *ngIf="isLoading"></loading>
    <mat-dialog-content>
        <app-test-details class="test-details-pdf-preview test-details-font" [inspectionResult]="inspectionResult"></app-test-details>
    </mat-dialog-content>
</main>
<div mat-dialog-actions class="actions">
  <button  mat-stroked-button class="close" (click)="onClose()">{{ "General.Close" | i18next }}</button>
  <button mat-raised-button color="accent" (click)="downloadAsPdf()">{{ 'InspectionResult.DownloadAsPDF' | i18next }}</button>
</div>
