
<div class="header-branch-select">
  <loading *ngIf="isLoading"></loading>
    <form class="header-select-branch-form">
      <mat-form-field class="branch-select" appearance="fill">
        <input
          type="text"
          matInput
          [formControl]="selectFormControl"
          [matAutocomplete]="auto"
          placeholder="{{ 'Branch.Select' | i18next }}"
          class="selected-branch-input"
        />
        <mat-autocomplete [panelWidth]="'auto'" class="select-branch-autocomplete" #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let branch of filteredBranches | async"
            [value]="branch"
            [ngClass]="allowSelection(branch.name)"
          >
            {{ branch.name || "Branch.NoMatch" | i18next }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
</div>

