import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FocusItemDto } from '../models/focus-item';

@Injectable({
  providedIn: 'root'
})
export class FocusItemsService {

  constructor(private http: HttpClient) { }

  saveFocusItem(focusItem: FocusItemDto) {
    return this.http.post(`${environment.apiBaseUrl}/focus-items`, focusItem,  { responseType: 'text' });
  }

  deleteFocusItem(inspectionItemId: string) {
    return this.http.delete(`${environment.apiBaseUrl}/focus-items/${inspectionItemId}`);
  }
}
