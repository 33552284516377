<main class="confirm-dialog-wrapper">
  <div mat-dialog-title class="title">{{ data.title | i18next }}</div>
  <div mat-dialog-content>
    <p class="text-m">{{ data.question | i18next }}{{ data.name }}</p>
  </div>
  <div mat-dialog-actions class="justify-right actions-container">
    <button mat-stroked-button [mat-dialog-close]="false" color="primary" cdkFocusInitial>
      {{ "General.Cancel" | i18next }}
    </button>
    <button mat-raised-button [color]="confirmButtonColor" [mat-dialog-close]="true">
      {{ data.confirmButtonText | i18next }}
    </button>
  </div>
</main>
