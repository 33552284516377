import { Component, Input } from '@angular/core';
import { InspectionFailureReasonsGalleryDto } from 'src/app/models/inspection-result';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-failure-reasons-group-gallery',
  templateUrl: './failure-reasons-group-gallery.component.html'
})
export class FailureReasonsGroupGalleryComponent {
  @Input() inspectionId!: string;
  isLoading = false;
  inspectionFailureReasonsGallery: InspectionFailureReasonsGalleryDto[] = [];

  constructor(private galleryService: GalleryService) {}

  ngOnInit(): void {
    this.getGallery();
  }

  getGallery() {
    this.isLoading = true;
    this.galleryService.getInspectionGalleryGroupedByFailureReason(this.inspectionId).subscribe({
      next: (data) => {
        this.inspectionFailureReasonsGallery = data;
        this.isLoading = false;
      },
      error: () => (this.isLoading = false),
    });
  }
}
