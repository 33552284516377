import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigurationListModel } from '../models/configuration-list.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationListService {

  constructor(private http: HttpClient) { }

  getConfiguration(configurationType: number): Observable<ConfigurationListModel[]> {
    return this.http.get<ConfigurationListModel[]>(`${environment.apiBaseUrl}/inspection-configurations/${configurationType}`);
  }
}
