import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})

export class RoleAccessGuard {
    constructor(private router: Router) { }

    canActivate() {
        const hasAccess = !!sessionStorage.getItem("user_roles");

        if (!hasAccess) {
            this.router.parseUrl("/select-branch");
        }
    }
}