import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditorAccessGuard {
  userRoles = (sessionStorage.getItem('user_roles') as string).split(',');  

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const branchId = route.paramMap.get('branchId');
    const isAuditor =
      typeof this.userRoles === "string"
        ? this.userRoles === "yardqualitycheck-api.doka.com/yard.auditor"
        : !!this.userRoles.find((role: string) => role === "yardqualitycheck-api.doka.com/yard.auditor");

    if (!isAuditor) {
      this.router.navigateByUrl(`branch/${branchId}/dashboard`);
    }
    return true;
  }
}
