import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import i18next from "i18next";
import { Subject } from "rxjs";

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  firstPageLabel = i18next.t("Pagination.FirstPageLabel");
  itemsPerPageLabel = i18next.t("Pagination.ItemsPerPageLabel");
  lastPageLabel = i18next.t("Pagination.LastPageLabel");
  nextPageLabel = i18next.t("Pagination.NextPageLabel");
  previousPageLabel = i18next.t("Pagination.PreviousPageLabel");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return i18next.t("Pagination.OnePage");
    }
    const amountPages = Math.ceil(length / pageSize);
    return i18next.t("Pagination.MultiplePages", { currentPage: page + 1, totalPages: amountPages });
  }
}
