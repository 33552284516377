import { Component, Input, OnInit } from '@angular/core';
import { ExportGalleryOverviewDto } from 'src/app/models/export-pdf.model';
import { InspectionResultDetailsDto, InspectionResultGallery } from 'src/app/models/inspection-result';
import { ExportPdfService } from 'src/app/services/export-pdf.service';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-inspection-groups-group-gallery',
  templateUrl: './inspection-groups-group-gallery.component.html'
})
export class InspectionGroupsGroupGalleryComponent implements OnInit {
  @Input() inspectionResultDetails!: InspectionResultDetailsDto;
  isLoading = false;
  failureReasonsList: InspectionResultGallery[] = [];

  constructor(private galleryService: GalleryService,
    private exportPdfService: ExportPdfService) {}

  ngOnInit(): void {
    this.getGallery();
  }

  get hasDataAvailable(){
    return this.failureReasonsList.length > 0;
  }

  getGallery() {
    this.isLoading = true;
    this.galleryService.getInspectionGalleryGroupedByInspectionGroup(this.inspectionResultDetails.inspectionId).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.failureReasonsList = data;
      },
      error: () => (this.isLoading = false),
    });
  }

  downloadAsPdf(){
    var exportGalleryOverview: ExportGalleryOverviewDto = {
      failureReasonsList: this.failureReasonsList,
      inspectionResultDetails: this.inspectionResultDetails
    };
    this.exportPdfService.galleryOverviewGeneratePdf(exportGalleryOverview);
  }
}
