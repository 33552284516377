import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  selectedLanguage = 'en';
  constructor(@Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) {
    this.i18NextService.events.initialized.subscribe((e) => {
      if (e) {
        this.updateState(this.i18NextService.language);
      }
    }); }

  translate(key: string){
    const fallbackLanguage = "en";
    const translation = this.i18NextService.getResource(this.selectedLanguage.toLowerCase(), 'translation', key);
    const fallbackTranslation = this.i18NextService.getResource(fallbackLanguage, 'translation', key);

    if (translation && Object.keys(translation).length > 0) {
      return translation;
    } else {     
      return fallbackTranslation;
    }
  }

  changeLanguage(lang: string | null){
    if ( (lang != null) && (lang.toLowerCase() !== this.selectedLanguage)) {
      this.i18NextService.changeLanguage(lang.toLowerCase()).then(x => {
        this.updateState(lang);
        document.location.reload();
      });
    }
  }

  private updateState(lang: string | null) {
    if (lang != null){
      this.selectedLanguage = lang.toUpperCase();}
  }
}
