import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { InspectionFailureReasonsGalleryDto, InspectionItemsGalleryDto, InspectionResultGallery } from "../models/inspection-result";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GalleryService {
  constructor(private http: HttpClient) {}

  getImageThumbnail(inspectionItemFailureReasonId: string) {
    return `${environment.apiBaseUrl}/inspection-item-failure-resons/image-thumbnail/${inspectionItemFailureReasonId}`;
  }

  getImage(inspectionItemFailureReasonId: string) {
    return `${environment.apiBaseUrl}/inspection-item-failure-resons/image/${inspectionItemFailureReasonId}`;
  }
  
  getResizedImage(inspectionItemFailureReasonId: string, width: number){
    return `${environment.apiBaseUrl}/inspection-item-failure-resons/resized-image/${inspectionItemFailureReasonId}?width=${width}`;
  }

  getResizedImageByPercent(inspectionItemFailureReasonId: string, percent: number){
    return `${environment.apiBaseUrl}/inspection-item-failure-resons/resized-image/${inspectionItemFailureReasonId}?percent=${percent}`;
  }

  getInspectionGalleryGroupedByInspectionGroup(
    inspectionId: string
  ): Observable<InspectionResultGallery[]> {
    let queryParams = new HttpParams().set("culture", localStorage.getItem("i18nextLng") || "en");

    return this.http.get<InspectionResultGallery[]>(`${environment.apiBaseUrl}/inspections/${inspectionId}/inspection-groups-group-gallery`, {
      params: queryParams,
    });
  }

  getInspectionGalleryGroupedByFailureReason(
    inspectionId: string
  ): Observable<InspectionFailureReasonsGalleryDto[]> {
    let queryParams = new HttpParams().set("culture", localStorage.getItem("i18nextLng") || "en");

    return this.http.get<InspectionFailureReasonsGalleryDto[]>(`${environment.apiBaseUrl}/inspections/${inspectionId}/failure-reasons-group-gallery`, {
      params: queryParams,
    });
  }

  getInspectionGalleryGroupedByItem(
    inspectionId: string
  ): Observable<InspectionItemsGalleryDto[]> {
    let queryParams = new HttpParams().set("culture", localStorage.getItem("i18nextLng") || "en");

    return this.http.get<InspectionItemsGalleryDto[]>(`${environment.apiBaseUrl}/inspections/${inspectionId}/inspection-items-group-gallery`, {
      params: queryParams,
    });
  }
}
