import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormControl, Validators, AbstractControl } from "@angular/forms";
import { Router } from "@angular/router";
import { BranchesService } from "src/app/services/branches.service";
import { EventBusService } from "src/app/services/event-bus.service";
import { SelectBranchComponentBase } from "../select-branch-component-base";

@Component({
  selector: "select-branch",
  templateUrl: "./select-branch.component.html",
  styleUrls: ["./select-branch.component.scss"],
})
export class SelectBranchComponent extends SelectBranchComponentBase implements OnInit, AfterViewInit {
  override selectFormControl = new FormControl(this.selectedBranch, [Validators.required, this.branchValidator]);

  constructor(protected override router: Router, 
    protected override branchesService: BranchesService, 
    protected override eventBusService: EventBusService) {
    super(branchesService, router, eventBusService);
  }

  saveSelectedBranch() {
    if (this.selectedBranch && this.selectFormControl.valid) {
      this.router.navigateByUrl(`branch/${this.selectedBranch.id}/dashboard`);
    }
  }

  branchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    return typeof control.value === "string" ? { invalidBranch: true } : null;
  }
}
