import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private snackBar: MatSnackBar, private translationService: TranslationService) { }

  openSnackBar(messageKey: string, action?: string ) {
    const message = this.translationService.translate(messageKey);
    this.snackBar.open(message, action, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 1000
    });
  }
}
