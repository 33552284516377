export const scopes = Object.freeze({
  email: 'email',
  offline_access: 'offline_access',
  openid: 'openid',
  phone:'phone',
  profile:'profile',
  read:'yardqualitycheck-api.doka.com/yardqualitycheck.read',
  write:'yardqualitycheck-api.doka.com/yardqualitycheck.write',
  roles: 'roles'
});
