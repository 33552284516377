import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthConfig, OAuthModule } from 'angular-oauth2-oidc';
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';

const authConfig: AuthConfig = environment.oAuth;

@NgModule({
  imports: [OAuthModule.forRoot()],
  providers: [
    AuthService,
    { provide: AuthConfig, useValue: authConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => async () =>
        authService.initAuth(),
      deps: [AuthService],
      multi: true,
    },
  ],
})
export class AuthModule { }
