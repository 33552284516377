import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { FocusItemDto, InspectionGroupConfigurationDto, SearchFocusItemDto } from 'src/app/models/focus-item';
import { InspectionItem } from 'src/app/models/inspection-item';
import {
  getInspectionGroupValidators,
  articleQuantityValidator,
  articleValidatorFn,
} from './inspection-group.validators';

@Component({
  selector: 'test-items',
  templateUrl: './inspection-group.component.html',
  styleUrls: ['./inspection-group.component.scss'],
})
export class InspectionGroupComponent implements OnInit, OnChanges {
  @Input() piecesData!: InspectionGroupConfigurationDto;
  totalStockQuantity!: number | undefined;
  @Output() searchValue = new EventEmitter<SearchFocusItemDto>();
  @Output() selectFocusItemEvent = new EventEmitter<FormGroupDirective>();
  @Output() addFocusItemEvent = new EventEmitter<FocusItemDto>();
  @Output() deleteFocusItemEvent = new EventEmitter<{ inspectionItemId: string; name: string }>();
  @Output() deleteItemEvent = new EventEmitter<{ inspectionItemId: string; name: string }>();
  @Output() isResetValidation = new EventEmitter<boolean>();
  inspectionGroupForm: FormGroup = new FormGroup({});
  loadNextClicks = 1;
  previousSelection!: InspectionItem;
  formDirective!: FormGroupDirective;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.inspectionGroupForm = this.fb.group(getInspectionGroupValidators(this.piecesData.availablePieces));
    this.watchFormFieldValueChange('article');
    this.watchFormFieldValueChange('quantity');
  }

  ngOnChanges(): void {
    if (this.piecesData.availableStockQuantity) {
      this.totalStockQuantity = Math.min(this.piecesData.availablePieces, this.piecesData.availableStockQuantity);
      this.getArticleQuantityValidation(!!this.totalStockQuantity);
    }
  }

  watchFormFieldValueChange(fieldName: string) {
    this.inspectionGroupForm.controls[fieldName].valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((value) => {
          if (fieldName === 'article') {
            this.filterTimberBeams(value || '');
          } else {
            const isQuantityValueExceeded =
              (this.piecesData.availableStockQuantity && this.totalStockQuantity && value > this.totalStockQuantity) ||
              this.piecesData.isStockQuantityExceeded;

            return isQuantityValueExceeded
              ? this.getArticleQuantityValidation(!!this.totalStockQuantity)
              : this.getArticleQuantityValidation();
          }
        })
      )
      .subscribe(() => {});
  }

  getArticleQuantityValidation(stockQuantity?: boolean) {
    if (this.formDirective) {
      const formControl = this.formDirective.form.controls;
      formControl['article'].setValidators([
        articleQuantityValidator(stockQuantity),
        articleValidatorFn(),
        Validators.required,
      ]);
      formControl['quantity'].setValidators([Validators.required, Validators.min(1)]);
      formControl['article'].updateValueAndValidity();
    }
  }

  onSelectionChange(formDirective: FormGroupDirective) {
    this.selectFocusItemEvent.emit(formDirective);

    const isDifferentFormDirective =
      this.piecesData.previousFormDirective && this.piecesData.previousFormDirective !== formDirective;
    const isDifferentValueSelection =
      this.previousSelection && this.previousSelection.name !== this.inspectionGroupForm.controls['article'].value.name;

    if (isDifferentFormDirective) {
      this.piecesData.previousFormDirective.resetForm();
      this.resetQuantityValidation();
    }

    if (isDifferentValueSelection) {
      this.resetQuantityValidation();
    }

    this.previousSelection = this.inspectionGroupForm.controls['article'].value;
  }

  resetQuantityValidation() {
    this.piecesData.availableStockQuantity = undefined;
    this.isResetValidation.emit();
    this.getArticleQuantityValidation();
  }

  addFocusItem(formDirective: FormGroupDirective) {
    if (this.inspectionGroupForm.valid) {
      const focusItems = this.inspectionGroupForm.controls['article'].value as unknown as InspectionItem;
      this.addFocusItemEvent.emit({
        itemId: focusItems.itemId,
        quantity: this.inspectionGroupForm.controls['quantity'].value as unknown as number,
      });
      this.formDirective = formDirective;
    }
  }

  deleteFocusItem(inspectionItemId: string, name: string) {
    this.deleteFocusItemEvent.emit({ inspectionItemId, name });
  }

  deleteItem(inspectionItemId: string, name: string) {
    this.deleteItemEvent.emit({ inspectionItemId, name });
  }

  loadNextFocusItems() {
    this.searchValue.emit({
      searchText: this.inspectionGroupForm.controls['article'].value,
      inspectionId: this.piecesData.inspectionId as string,
      inspectionGroupId: this.piecesData.inspectionGroup?.inspectionGroupId,
      currentPage: this.loadNextClicks++,
    });
  }

  displayFn(item: InspectionItem): string {
    return item ? `${item.articleIdentifier} ${item.name}` : '';
  }

  allowSelection(option: string): { [className: string]: boolean } {
    return {
      'no-data': option === '',
    };
  }

  private filterTimberBeams(value: string) {
    this.loadNextClicks = 1;
    if (value && value.length > 2) {
      this.searchValue.emit({
        searchText: value,
        inspectionId: this.piecesData.inspectionId as string,
        inspectionGroupId: this.piecesData.inspectionGroup?.inspectionGroupId,
        currentPage: 0,
      });
    } else if (this.piecesData.inspectionGroup) {
      this.piecesData.inspectionGroup.focusItemsOptions = [
        { articleIdentifier: '', inspectionItemId: '', itemId: '', name: '', quantity: 0 },
      ];
    }
  }
}
