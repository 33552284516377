import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface ImageDialogData {
  itemName: string;
  failureReasonId: number;
  imageData: string;
}

@Component({
  selector: "app-image-modal",
  templateUrl: "./image-modal.component.html",
  styleUrls: ["./image-modal.component.scss"],
})
export class ImageModalComponent {
  isZoomedIn = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ImageDialogData) {}

  zoomIn(): void {
    this.isZoomedIn = true;
  }

  zoomOut(): void {
    this.isZoomedIn = false;
  }
}
