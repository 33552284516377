<div class="mat-elevation-z8 comments-container" *ngIf="hasComments()">
  <table mat-table [dataSource]="dataSource" class="comments-table">
    <ng-container matColumnDef="inspection-item">
      <th mat-header-cell *matHeaderCellDef>{{ "InspectionResult.YardCheckComments.ArticleDetails" | i18next }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="article-identifier">{{ element.inspectionItem.articleIdentifier }}</div>
        <div>{{ element.inspectionItem.name }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="failures">
      <th mat-header-cell *matHeaderCellDef>{{ "InspectionResult.YardCheckComments.Failure" | i18next }}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngFor="let failure of element.failureReasonIds">
          {{ "InspectionResult.ItemFailureReasons.List." + failure | i18next }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>{{ "InspectionResult.YardCheckComments.Comment" | i18next }}</th>
      <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<p *ngIf="!hasComments()">
  {{ "InspectionResult.YardCheckComments.NoComments" | i18next }}
</p>
