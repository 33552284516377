import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Branch } from 'src/app/models/branch.model';
import { BranchesService } from 'src/app/services/branches.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { SelectBranchComponentBase } from '../select-branch-component-base';

@Component({
  selector: 'app-header-branch-select',
  templateUrl: './header-select-branch.component.html',
  styleUrls: ['./header-select-branch.component.scss']
})
export class HeaderSelectBranchComponent extends SelectBranchComponentBase implements OnInit {
  @Input() override branchId!: string;

  constructor(protected override branchesService: BranchesService, 
    protected override router: Router,
    protected override eventBusService: EventBusService) {
      super(branchesService, router, eventBusService);
    }

  override setSelectedBranch(branch?: Branch | null) {
    if (branch?.id !== this.selectedBranch?.id) {
      this.selectedBranch = branch ? branch : this.selectedBranch;
      this.selectFormControl.setValue(this.selectedBranch);
      if (branch) {
        this.router.navigate(["branch", this.selectedBranch?.id, "dashboard"]);
      }
    }
  }
}
