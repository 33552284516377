<div class="pdf-button">
  <button mat-raised-button color="accent" (click)="downloadAsPdf()">
    {{ "InspectionResult.DownloadAsPDF" | i18next }}
  </button>
</div>

<div *ngIf="!hasDataAvailable">
  {{ "InspectionResult.Tabs.GalleryOverview.NoDataAvailable" | i18next }}
</div>

<div class="failures-list-container gallery-overview">
  <loading *ngIf="isLoading"></loading>
  <div class="failures-list" *ngFor="let failureReason of failureReasonsList">
    <h2>
      {{ "ConfigurationList.InspectionGroup." + failureReason.inspectionGroupIdentifier | i18next }}
    </h2>
    <div *ngFor="let inspectionItem of failureReason.inspectionItems">
      <h3>{{ inspectionItem.articleIdentifier }} - {{ inspectionItem.name }}</h3>
      <div class="failure-image-container">
        <div *ngFor="let inspectionItemFailureReason of inspectionItem.inspectionItemFailureReasons">
          <h4>
            {{ "InspectionResult.ItemFailureReasons.List." + inspectionItemFailureReason.failureReasonId | i18next }}
          </h4>
          <div *ngFor="let inspectionItemFailureReasonId of inspectionItemFailureReason.inspectionItemFailureReasonIds">
            <app-image-slider
              [inspectionItemImageDetails]="{
                inspectionItemFailureReasonId: inspectionItemFailureReasonId,
                failureReasonId: inspectionItemFailureReason.failureReasonId,
                inspectionItemName: inspectionItem.name,
                articleIdentifier: inspectionItem.articleIdentifier
              }"
            ></app-image-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
