import { Component, Input } from "@angular/core";
import { InspectionResultModel } from "src/app/models/inspection-result";

@Component({
  selector: "app-test-details",
  templateUrl: "./test-details.component.html",
  styleUrls: ["./test-details.component.scss"],
})
export class TestDetailsComponent {
  @Input() inspectionResult!: InspectionResultModel;

  constructor() {}
}
