import { Component, Input, OnInit } from "@angular/core";
import {
  InspectionResultDetailsDto,
  InspectionResultGallery,
} from "src/app/models/inspection-result";
import { GalleryGroupTypeEnum } from "src/app/enums/gallery-group-type.enum";

@Component({
  selector: "app-gallery-overview",
  templateUrl: "./gallery-overview.component.html",
  styleUrls: ["./gallery-overview.component.scss"],
})
export class GalleryOverviewComponent implements OnInit {
  @Input() inspectionResultDetails!: InspectionResultDetailsDto;
  isLoading = false;
  gallerySelectedGroup!: GalleryGroupTypeEnum;
  failureReasonsList: InspectionResultGallery[] = [];
  galleryGroupType = GalleryGroupTypeEnum;

  constructor() {}

  ngOnInit(): void {
    this.gallerySelectedGroup = this.galleryGroupType.GroupByInspectionGroup;
  }

  onSelectionChange(galleryOverviewGroup: GalleryGroupTypeEnum) {
    this.gallerySelectedGroup = galleryOverviewGroup;
  }
}
