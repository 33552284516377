import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfigurationListPage } from './pages/configuration-list/configuration-list.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { ExportedInspectionPage } from './pages/exported-inspection/exported-inspection.page';
import { InspectionResultPage } from './pages/inspection-result/inspection-result.page';
import { SelectBranchPage } from './pages/select-branch/select-branch.page';
import { TestSheetPage } from './pages/test-sheet/test-sheet.page';
import { AuditorAccessGuard} from './guards/auditor-access.guard';
import { LoginCallbackPage } from './pages/login-callback/login-callback.page';
import { RoleAccessGuard } from './guards/role-access.guard';

const routes: Routes = [
  { path: '', redirectTo: 'select-branch', pathMatch: 'full' },
  { path: 'login-callback', component: LoginCallbackPage },
  { path: 'branch/:branchId/dashboard', component: DashboardPage, canActivate: [RoleAccessGuard] },
  { path: 'branch/:branchId/test-sheet/:id', component: TestSheetPage, canActivate: [AuditorAccessGuard] },
  { path: 'branch/:branchId/configuration-list/:id', component: ConfigurationListPage, canActivate: [RoleAccessGuard] },
  { path: 'select-branch', component: SelectBranchPage, canActivate: [RoleAccessGuard] },
  { path: 'branch/:branchId/exported-inspection/:id', component: ExportedInspectionPage, canActivate: [RoleAccessGuard] },
  { path: 'branch/:branchId/inspection-result/:id', component: InspectionResultPage, canActivate: [RoleAccessGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
