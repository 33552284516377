import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePage } from 'src/app/pages/base-page';
import { EventBusService } from 'src/app/services/event-bus.service';
import { InspectionService } from 'src/app/services/inspections.service';

@Component({
  templateUrl: './exported-inspection.page.html',
  styleUrls: ['./exported-inspection.page.scss'],
})
export class ExportedInspectionPage extends BasePage implements OnInit {
  inspectionId!: string;

  constructor(private inspectionsService: InspectionService, 
    private router: Router,
    protected override route: ActivatedRoute,
    protected override eventBusService: EventBusService,) {
    super(route, eventBusService);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.emitBranchId(params);
      this.inspectionId = params['id'];
    });
  }

  exportInspection() {
    this.inspectionsService.exportInspection(this.inspectionId).subscribe((response) => {
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: response.type }));
      downloadLink.setAttribute('download', `Inspection-${this.inspectionId}.csv`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  goToDashbord() {
      this.router.navigateByUrl(`branch/${this.branchId}/dashboard`);
  }
}
