import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/pages/base-page';
import { ConfigurationListModel } from 'src/app/models/configuration-list.model';
import { ConfigurationListService } from 'src/app/services/configuration-list.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-configuration-list',
  templateUrl: './configuration-list.page.html',
  styleUrls: ['./configuration-list.page.scss'],
})
export class ConfigurationListPage extends BasePage implements OnInit {
  configurationList: ConfigurationListModel[] = [];
  totalItems = 0;
  totalPieces = 0;
  isLoading = false;

  constructor(
    private configurationListService: ConfigurationListService,
    private toastService: ToastService,
    protected override route: ActivatedRoute,
    protected override eventBusService: EventBusService,
  ) {
    super(route, eventBusService);
  }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.emitBranchId(params);
      this.getConfiguration(params['id']);
    });
  }

  private getConfiguration(configurationType: number) {
    this.isLoading = true;
    this.configurationListService.getConfiguration(configurationType).subscribe({
      next: (data) => {
        this.configurationList = data;
        this.totalItems = data.reduce((accumulator, value) => {
          return accumulator + value.totalItemsToTest;
        }, 0);
        this.totalPieces = data.reduce((accumulator, value) => {
          return accumulator + value.totalPiecesToTest;
        }, 0);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.openSnackBar('General.UnknownError');
      }
    });
  }
}
