<main class="failure-reason-image-modal">
  <h1 mat-dialog-title>{{ data.itemName }}</h1>
  <h2 mat-dialog-title>
    {{ "InspectionResult.ItemFailureReasons.List." + data.failureReasonId | i18next }}
  </h2>
  <mat-dialog-content class="mat-typography image-container" [ngClass]="{ 'zoom-in': isZoomedIn }">
    <img [src]="data.imageData" />
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ "General.Close" | i18next }}</button>
    <div class="zoom-actions">
      <mat-icon aria-hidden="false" aria-label="Zoom in icon" fontIcon="zoom_in" (click)="zoomIn()"></mat-icon>
      <mat-icon aria-hidden="false" aria-label="Zoom out icon" fontIcon="zoom_out" (click)="zoomOut()"></mat-icon>
    </div>
  </div>
</main>
