<div class="page-container">
  <div class="page-title">
    <div id="inspection-result-title" class="flex justify-between mr-4">{{ 'InspectionResult.Title' | i18next }}</div>
  </div>
  <loading *ngIf="isLoading"></loading>
  <section *ngIf="!isLoading" class="page-body inspection-result-wrapper">
    <header>
      <span class="mr-1">{{ 'General.Date' | i18next }}:</span>
      <span class="mr-4">{{getDateFormat(inspectionResult.header.dateOfInspection)}}</span>
      <span class="mr-1">{{ 'InspectionResult.Auditor' | i18next }}:</span>
      <span class="mr-4">{{inspectionResult.header.auditorName}}</span>
      <div *ngIf="inspectionResult.header.completedByName">
        <span class="mr-1">{{ 'InspectionResult.CompletedBy' | i18next }}:</span>
        <span class="mr-4">{{inspectionResult.header.completedByName}}</span>
      </div>
      <span class="mr-1">{{ 'InspectionResult.TargetValue' | i18next }}:</span>
      <span>{{inspectionResult.header.targetNumberOfItems}}</span>
    </header>
    <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false">
      <mat-tab class="details" label="{{'InspectionResult.Tabs.TestDetails' | i18next}}">
        <div class="pdf-button">
          <button mat-raised-button color="accent" (click)="previewPdf()">{{ 'InspectionResult.PreviewPDF' | i18next }}</button>
        </div>
        <app-test-details
        [inspectionResult]="inspectionResult"
        ></app-test-details>
      </mat-tab>
      <mat-tab bodyClass="gallery-overview-tab-wrapper" label="{{'InspectionResult.Tabs.GalleryOverview.Title' | i18next}}">
        <app-gallery-overview [inspectionResultDetails]="inspectionResultDetails"></app-gallery-overview>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>
