<div class="page-container">
  <div class="page-title">
    <div class="flex justify-between mr-4">
      {{ 'TestSheet.Title' | i18next }}
      <section class="action-buttons">
        <button mat-stroked-button color="primary" class="mr-2" (click)="resetConfiguration()">
          <mat-icon class="configuration-icon">restore</mat-icon> {{ 'TestSheet.ResetConfiguration' | i18next }}
        </button>
        <button mat-stroked-button color="primary" class="mr-2" (click)="goToConfiguration()">
          <mat-icon class="configuration-icon">error_outline</mat-icon> {{ 'TestSheet.ConfigurationList' | i18next }}
        </button>
        <button mat-raised-button color="accent" (click)="export()">{{ 'TestSheet.Export' | i18next }}</button>
      </section>
    </div>
    <div class="page-subtitle">
      <span class="mr-6">{{ 'TestSheet.TotalPieces' | i18next }} {{totalPieces}} </span>{{ 'TestSheet.AvailablePieces' |
      i18next }} {{availablePieces}}
    </div>
  </div>
  <div class="page-body small test-sheet-body">
    <loading *ngIf="isLoading"></loading>
    <header class="header">
      <div></div>
      <div>{{ 'TestSheet.TestArticles' | i18next }}</div>
      <div class="text-center">{{ 'TestSheet.Quantity' | i18next }}</div>
      <div class="text-center">{{ 'TestSheet.Delete' | i18next }}</div>
    </header>
    <test-items
      *ngFor="let inspectionGroup of inspection?.inspectionGroups"
      [piecesData]="{inspectionGroup: inspectionGroup, inspectionId: inspection?.inspectionId,availablePieces: availablePieces,areTotalPiecesLoaded:areTotalPiecesLoaded, totalPieces: totalPieces, availableStockQuantity: availableStockQuantity, previousFormDirective: previousFormDirective, isStockQuantityExceeded: isStockQuantityExceeded}"
      (searchValue)="searchValue($event)"
      (addFocusItemEvent)="addFocusItem($event)"
      (selectFocusItemEvent)="onFocusItemSelectionChange($event)"
      (deleteFocusItemEvent)="deleteFocusItem($event)"
      (deleteItemEvent)="deleteItem($event)"
      (isResetValidation)="resetAvailableStockQuantity()"
    ></test-items>
  </div>
</div>
