import { Injectable } from "@angular/core";
import { TranslationService } from "./translation.service";
import { ExportGalleryOverviewDto } from "../models/export-pdf.model";
import { ExportGalleryOverviewService } from "./export-gallery-overview.service";
import { ExportTestDetailsService } from "./export-test-details.service";
import { InspectionResultDetailsDto } from "../models/inspection-result";

@Injectable({
  providedIn: 'root'
})
export class ExportPdfService {

  constructor(private translationService: TranslationService) {}

  testDetailsGeneratePdf(callback: () => void, galleryOverviewHeader: InspectionResultDetailsDto) {
    let exportTestDetailsService = new ExportTestDetailsService(this.translationService, galleryOverviewHeader);
    exportTestDetailsService.downloadPdf(callback);
  }

  galleryOverviewGeneratePdf(exportGalleryOverview: ExportGalleryOverviewDto) {
    let exportGalleryOverviewService = new ExportGalleryOverviewService(this.translationService, exportGalleryOverview);
    exportGalleryOverviewService.downloadPdf();
  }
}