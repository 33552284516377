import jsPDF from "jspdf";
import { InspectionResultDetailsDto } from "../models/inspection-result";
import { TranslationService } from "./translation.service";
import i18next from "i18next";

export class ExportPdfBaseService {
  readonly inspectionDetailsIncrement = 30;

  protected jsPdf: jsPDF;
  protected currentPositionX: number;
  protected currentPositionY: number;
  protected currentIncrement: number;
  protected inspectionResultTitleFontSize: number;
  protected inspectionDetailsFontSize: number;
  protected inspectionDate: string | null;
  protected auditorName: string;
  protected completedByName: string;
  protected targetValue: number;
  protected branchName: string;

  constructor(
    protected inspectionResultDetails: InspectionResultDetailsDto,
    protected translationService: TranslationService
  ) {
    this.jsPdf = new jsPDF();
    this.currentPositionX = 10;
    this.currentPositionY = 20;
    this.currentIncrement = 20;
    this.inspectionResultTitleFontSize = 20;
    this.inspectionDetailsFontSize = 14;
    this.auditorName = inspectionResultDetails.auditorName;
    this.completedByName = inspectionResultDetails.completedByName;
    this.targetValue = inspectionResultDetails.targetNumberOfItems;
    this.branchName = inspectionResultDetails.branchName;
    this.inspectionDate = this.inspectionResultDetails.dateOfInspection;
    
  }

  protected addInspectionResultTitle(): void {
    this.jsPdf.setFontSize(this.inspectionResultTitleFontSize);
    this.jsPdf.addFont("./../../assets/fonts/Roboto/Roboto-Regular.ttf", "Roboto", "normal");
    this.jsPdf.setFont("Roboto");

    const inspectionResultTitle = this.translationService.translate("InspectionResult.Title");

    this.jsPdf.text(inspectionResultTitle, this.currentPositionX, this.currentPositionY);
    this.currentPositionY += this.inspectionDetailsIncrement;
    this.currentPositionX += this.currentIncrement;
  }

  protected addInspectionDetails(): void {
    this.jsPdf.setFontSize(this.inspectionDetailsFontSize);

    const inspectionDetails = `${this.translationService.translate("General.Date")}: ${
      this.inspectionDate
    } ${this.translationService.translate("InspectionResult.Auditor")}: ${this.auditorName} ${
      this.completedByName
        ? `${this.translationService.translate("InspectionResult.CompletedBy")}: ${this.completedByName}`
        : ""
    } ${this.translationService.translate("InspectionResult.TargetValue")}: ${this.targetValue}`;
    this.jsPdf.text(inspectionDetails, this.currentPositionX, this.currentPositionY);

    this.currentPositionY += this.currentIncrement;
  }
}
