import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { InspectionResultComments } from "src/app/models/inspection-result";

@Component({
  selector: "app-comments-table",
  templateUrl: "./comments-table.component.html",
  styleUrls: ["./comments-table.component.scss"],
})
export class CommentsTableComponent implements OnInit, AfterViewInit {
  @Input() inspectionComments!: InspectionResultComments[];
  displayedColumns: string[] = ["inspection-item", "failures", "comment"];
  dataSource!: MatTableDataSource<InspectionResultComments>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor() {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource<InspectionResultComments>(this.inspectionComments);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  hasComments() {
    return this.dataSource.filteredData.length >= 1;
  }
}
