import { ActivatedRoute, Params } from "@angular/router";
import { EventBusActions } from "../models/event-bus-actions";
import { EmitEvent, EventBusService } from "../services/event-bus.service";

export class BasePage {
    branchId!: string;

    constructor(protected route: ActivatedRoute,
        protected eventBusService: EventBusService) { }

    emitBranchId(params: Params): void {
        this.branchId = params['branchId'];
        this.eventBusService.emit(new EmitEvent(EventBusActions.branchIdReceived, this.branchId));
    }
}