import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventBusActions } from 'src/app/models/event-bus-actions';
import { AuthService } from 'src/app/services/auth.service';
import { EmitEvent, EventBusService } from 'src/app/services/event-bus.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login-callback-page',
  templateUrl: './login-callback.page.html'
})
export class LoginCallbackPage implements OnInit {
  isLoading = false;
  constructor(private router: Router, 
    private eventBusService: EventBusService,
    private userService: UserService,
    private toastService: ToastService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this.getRoles();
  }

  private getRoles() {
    this.isLoading = true;
    this.userService.getRoles().subscribe({
      next: (roles) => {
        if(roles.length > 0){
          sessionStorage.setItem('user_roles', roles.join(','));
          this.saveUser();             
        }
        else {
          this.isLoading = false;
          this.router.navigate(["/select-branch"]);
        }
      },
      error: () => {
        this.isLoading = false;
        this.toastService.openSnackBar("General.UnknownError");
      },
    });
  }

  private redirectToRoute(){
    const redirectUrl = sessionStorage.getItem('redirectUrl');

    if (redirectUrl) {      
      const branchId = this.extractBranchId(redirectUrl);
      if(branchId){
        this.eventBusService.emit(new EmitEvent(EventBusActions.branchIdReceived, branchId));
      }
      sessionStorage.removeItem('redirectUrl');
      this.router.navigateByUrl(redirectUrl);
    }
    else {
      sessionStorage.removeItem('redirectUrl');
      this.router.navigate(["/select-branch"]);
    }
  }

  private extractBranchId(redirectUrl: string): string {
    const splittedUrl = redirectUrl.split('/');
    const branchId = splittedUrl[2];

    return branchId;
  }

  private saveUser() {
    this.userService.saveUser(this.authService.getCurrentUser()).subscribe({
      next: () => {
        this.isLoading = false;
        this.redirectToRoute();
      },
      error: () => {
        this.isLoading = false;
        this.toastService.openSnackBar("General.UnknownError");
      }   
    });  
  }
}
