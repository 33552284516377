import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionItemsService {

  constructor(private http: HttpClient) { }

  deleteItem(inspectionItemId: string) {
    return this.http.delete(`${environment.apiBaseUrl}/inspection-items/${inspectionItemId}`);
  }
}
